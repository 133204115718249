import React, { FC } from "react";
import styled from "styled-components";
import { ParagraphLarge } from "@logrock/pebbles";
import { up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { Trans } from "react-i18next";

import Container from "../Container";
import LinkedInLogo from "../../images/svg/linkedin.svg";
import LogoHorizontalDark from "../../images/logo--horizontal--dark.svg";
import Section from "../Section";

const Anchor = styled.a`
  color: ${({ theme }) => theme.colors.neutral[900]};
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: start;
  max-width: 1280px;

  ${up("desktop")} {
    flex-flow: row nowrap;
  }
`;

const Separator = styled.span`
  margin: 0 12px;
`;

const FooterSection = styled(Section)`
  margin-bottom: 0;
  padding-top: ${({ theme }) => theme.spacings.xxbig};
  padding-bottom: ${({ theme }) => theme.spacings.xxbig};

  background-color: ${({ theme }) => theme.colors.neutral[50]};
`;

const Footer: FC = () => {
  const isDesktop = useBreakpoint(up("desktop"));

  return (
    <FooterSection center noPadding id="contact-us">
      <StyledContainer>
        <ParagraphLarge>
          <b>
            <Trans>Contact us</Trans>:{" "}
          </b>
          {!isDesktop && <br />}
          <Anchor
            href="mailto:letsrock@logrock.com"
            style={{ marginLeft: isDesktop ? 12 : 0 }}
          >
            <Trans>letsrock@logrock.com</Trans>
          </Anchor>

          {!isDesktop && <br />}
          {isDesktop && <Separator>|</Separator>}
          <Anchor href="/privacy">
            <Trans>Privacy</Trans>
          </Anchor>

          {!isDesktop && <br />}
          {isDesktop && <Separator>|</Separator>}
          <Anchor href="/terms">
            <Trans>Terms</Trans>
          </Anchor>
        </ParagraphLarge>
        {isDesktop && (
          <a href="https://www.linkedin.com/company/logrockinc">
            <LinkedInLogo
              width={24}
              height={24}
              style={{ marginLeft: isDesktop ? 24 : 0 }}
            />
          </a>
        )}
        {!isDesktop && <br />}
        <LogoHorizontalDark
          width={150}
          height={30}
          style={{ marginLeft: isDesktop ? "auto" : undefined }}
        />
        {!isDesktop && <br />}
        {!isDesktop && (
          <a href="https://www.linkedin.com/company/logrockinc">
            <LinkedInLogo
              width={24}
              height={24}
              style={{ marginLeft: isDesktop ? 24 : 0 }}
            />
          </a>
        )}
      </StyledContainer>
    </FooterSection>
  );
};

export default Footer;
