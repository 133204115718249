import styled from "styled-components";

interface SectionProps {
  noPadding?: boolean;
  slimPadding?: boolean;
  thickPadding?: boolean;
  white?: boolean;
  center?: boolean;
  fullWidth?: boolean;
}

export const Section = styled.section<SectionProps>`
  padding-top: ${({ theme }) => theme.spacings.lg};
  padding-right: ${({ theme, fullWidth }) =>
    fullWidth ? "0px" : theme.spacings.lg};
  padding-bottom: ${({ noPadding, slimPadding, thickPadding }) => {
    if (noPadding) return "0px";
    if (slimPadding) return "100px";
    if (thickPadding) return "500px";
    return "200px";
  }};
  padding-left: ${({ theme, fullWidth }) =>
    fullWidth ? "0px" : theme.spacings.lg};
  overflow-x: clip;
  overflow-y: visible;

  background: ${({ white }) => (white ? "#FFFFFF" : undefined)};

  text-align: ${({ center }) => (center ? "center" : "left")};
`;
