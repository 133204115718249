import React, { FunctionComponent, useContext, useState } from "react";
import Logo from "../../images/logo--horizontal--dark.svg";
import {
  DesktopMenu,
  DesktopMenuButtons,
  HeaderLogo,
  HeaderWrapper,
  LinkButton,
  Menu,
  MobileMenu,
  MobileMenuButton,
  MobileMenuButtons,
  MobileMenuLink,
  MobileUnderlay,
} from "./Heder.styled";
import Icon from "@mdi/react";
import { mdiClose, mdiMenu } from "@mdi/js";
import { ThemeContext } from "styled-components";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import { up } from "styled-breakpoints";
import { Trans } from "gatsby-plugin-react-i18next";
import { Button } from "@logrock/pebbles";

interface HeaderProps {
  noPaddingDesktop?: boolean;
}

const Header: FunctionComponent<HeaderProps> = ({
  children,
  noPaddingDesktop = false,
}) => {
  const theme = useContext(ThemeContext);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const isDesktop = useBreakpoint(up("desktop"));

  const toggleMobileMenu = () => {
    setShowMobileMenu((showMobileMenu) => !showMobileMenu);
  };

  const linkClickCallback = () => {
    setShowMobileMenu(false);
  };

  return (
    <>
      <MobileMenu show={showMobileMenu}>
        <MobileMenuLink href="/#products" onClick={linkClickCallback}>
          <Trans>Products</Trans>
        </MobileMenuLink>
        <MobileMenuLink href="/#pricing" onClick={linkClickCallback}>
          <Trans>Pricing</Trans>
        </MobileMenuLink>
        <MobileMenuLink href="/#integrations" onClick={linkClickCallback}>
          <Trans>Integrations</Trans>
        </MobileMenuLink>
        <MobileMenuLink href="/#our-customers" onClick={linkClickCallback}>
          <Trans>Our Customers</Trans>
        </MobileMenuLink>
        <MobileMenuLink href="https://podcast.logrock.com/" target="_blank">
          <Trans>Our Podcast</Trans>
        </MobileMenuLink>
        <MobileMenuLink href="/faq" target="_blank">
          <Trans>FAQ</Trans>
        </MobileMenuLink>

        <MobileMenuButtons>
          <Button
            buttonSize="large"
            buttonStyle="outlined"
            onClick={() => (window.location.href = "https://app.logrock.com")}
          >
            <Trans>Login</Trans>
          </Button>
          <Button
            buttonStyle="primary"
            buttonSize="large"
            onClick={() =>
              (window.location.href = "https://app.logrock.com/onboarding")
            }
          >
            <Trans>Sign up</Trans>
          </Button>
        </MobileMenuButtons>
      </MobileMenu>
      <HeaderWrapper noPaddingDesktop={noPaddingDesktop}>
        <MobileUnderlay show={showMobileMenu} />
        <Menu>
          <HeaderLogo href="/#who-we-are">
            <Logo width={isDesktop ? 150 : 130} height={isDesktop ? 30 : 24} />
          </HeaderLogo>
          <DesktopMenu>
            <LinkButton
              buttonSize="large"
              buttonStyle="tertiary"
              onClick={() => {
                window.location.href = "/#products";
              }}
            >
              <Trans>Products</Trans>
            </LinkButton>
            <LinkButton
              buttonSize="large"
              buttonStyle="tertiary"
              onClick={() => {
                window.location.href = "/#pricing";
              }}
            >
              <Trans>Pricing</Trans>
            </LinkButton>
            <LinkButton
              buttonSize="large"
              buttonStyle="tertiary"
              onClick={() => {
                window.location.href = "/#integrations";
              }}
            >
              <Trans>Integrations</Trans>
            </LinkButton>
            <LinkButton
              buttonSize="large"
              buttonStyle="tertiary"
              onClick={() => {
                window.location.href = "/#our-customers";
              }}
            >
              <Trans>Our Customers</Trans>
            </LinkButton>
            <LinkButton
              buttonSize="large"
              buttonStyle="tertiary"
              onClick={() => {
                window.open("https://podcast.logrock.com/", "_blank");
              }}
            >
              <Trans>Our podcast</Trans>
            </LinkButton>
            <LinkButton
              buttonSize="large"
              buttonStyle="tertiary"
              onClick={() => {
                window.location.href = "/faq";
              }}
            >
              <Trans>FAQ</Trans>
            </LinkButton>
          </DesktopMenu>
          <DesktopMenuButtons>
            <Button
              buttonSize="large"
              onClick={() =>
                (window.location.href = "https://app.logrock.com/onboarding")
              }
            >
              <Trans>Sign up</Trans>
            </Button>
            <Button
              buttonSize="large"
              buttonStyle="outlined"
              onClick={() => (window.location.href = "https://app.logrock.com")}
            >
              <Trans>Login</Trans>
            </Button>
          </DesktopMenuButtons>
          <MobileMenuButton onClick={toggleMobileMenu}>
            <Icon
              path={showMobileMenu ? mdiClose : mdiMenu}
              size={theme.spacings.big}
              color={theme.colors.neutral[900]}
            />
          </MobileMenuButton>
        </Menu>
      </HeaderWrapper>
    </>
  );
};

export default Header;
