import { Button } from "@logrock/pebbles";
import { down, up } from "styled-breakpoints";
import styled from "styled-components";

export const HeaderWrapper = styled.div<{ noPaddingDesktop?: boolean }>`
  display: flex;
  position: sticky;
  z-index: 3;
  top: 0;
  align-items: center;
  justify-content: center;
  padding-top: ${({ theme }) => theme.spacings.md};
  padding-bottom: ${({ theme }) => theme.spacings.md};

  background: ${({ theme }) => theme.colors.white[100]};

  ${up("desktop")} {
    margin-bottom: ${({ noPaddingDesktop = false, theme }) =>
      noPaddingDesktop ? theme.spacings.md : "132px"};
    padding-top: ${({ theme }) => theme.spacings.xbig};
    padding-bottom: ${({ theme }) => theme.spacings.xbig};
  }
`;

export const HeaderLogo = styled.a`
  display: flex;
  z-index: 1;
  margin-right: ${({ theme }) => theme.spacings.lg};
`;

export const MobileMenuButton = styled.button`
  display: flex;
  z-index: 1;
  align-items: flex-end;
  margin-left: auto;
  padding: 0;

  border: none;

  background: none;

  ${up("desktop")} {
    display: none;
  }
`;

export const MobileUnderlay = styled.div<{ show?: boolean }>`
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  transform: translateX(${({ show }) => (show ? 0 : "-100%")});
  transition: 0.3s;

  background: white;
`;

export const MobileMenu = styled.nav<{ show?: boolean }>`
  display: flex;
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  box-sizing: border-box;
  flex-flow: column nowrap;
  width: 100vw;
  height: 100vh;
  padding-top: ${({ theme }) => theme.spacings.huge};
  padding-right: ${({ theme }) => theme.spacings.lg};
  padding-bottom: ${({ theme }) => theme.spacings.lg};
  padding-left: ${({ theme }) => theme.spacings.lg};
  overflow: hidden;

  transform: translateX(${({ show }) => (show ? 0 : "-100%")});
  transition: 0.3s;

  background: white;

  ${up("desktop")} {
    display: none;
  }
`;

export const MobileMenuLink = styled.a`
  display: block;

  color: ${({ theme }) => theme.colors.neutral[500]};
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 60px;
  text-decoration-line: underline;
`;

export const MenuLink = styled.a`
  display: block;

  color: ${({ theme }) => theme.colors.neutral[500]};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.01em;
  line-height: 60px;
  text-decoration-line: underline;
`;

export const MobileMenuButtons = styled.div`
  display: flex;
  bottom: 128px;
  box-sizing: border-box;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  margin-bottom: 80px;

  button {
    justify-content: center;
    width: 100%;
    margin: 8px;
  }
`;

export const DesktopMenu = styled.div`
  display: none;
  grid-template-columns: repeat(6, max-content);
  align-items: start;
  justify-content: space-between;
  column-gap: ${({ theme }) => theme.spacings.big};

  justify-items: start;

  ${up("desktop")} {
    display: grid;
  }
`;

export const DesktopMenuButtons = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;

  button,
  a {
    margin-left: ${({ theme }) => theme.spacings.md};
  }

  ${up("desktop")} {
    display: flex;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;
  width: 100%;
  max-width: 1280px;

  ${down("mobile")} {
    padding-right: ${({ theme }) => theme.spacings.xbig};
    padding-left: ${({ theme }) => theme.spacings.xbig};
  }
`;

export const LinkButton = styled(Button)`
  padding: 0;

  text-decoration: underline;
`;
